import buildFormActionCreator from './builders/form';
import messagesActionCreator from './messages';
import actionTypes from '../actiontypes/startImpersonation';
import UtilityHelper from '../utils/helpers';
import { actionTypeSections } from '../actiontypes';
import http from '../utils/http';

const startImpersonationActionCreator = {
  ...buildFormActionCreator(actionTypeSections.START_IMPERSONATION_REQUEST),

  resetMainState() {
    return {
      type: actionTypes.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());
    };
  },

  loadDataRequest() {
    return {
      type: actionTypes.IMPERSONATION_LOAD_REQUEST,
    };
  },

  loadDataSuccess(payload) {
    return {
      type: actionTypes.IMPERSONATION_LOAD_SUCCESS,
      payload,
    };
  },

  loadDataFailure(err) {
    return {
      type: actionTypes.IMPERSONATION_LOAD_FAILURE,
      payload: err,
    };
  },

  loadData(impersonationId) {
    return async (dispatch) => {
      dispatch(this.loadDataRequest());

      try {
        const { data: myClientInfoResData } = await http.getMyClientInfo();

        const {
          data: { associateId },
        } = await http.getAssociateFromImpersonationId(impersonationId);

        const { data: associateInfoResData } = await http.getAssociateInfo(
          associateId,
          myClientInfoResData.clientId
        );

        const payload = {
          clientId: myClientInfoResData.clientId,
          clientName: myClientInfoResData.clientName,
          associateId: associateInfoResData.associateId,
          associateDisplayName: `${associateInfoResData.firstName || 'No Data'} ${
            associateInfoResData.lastName || ''
          }`,
        };

        if (!payload.clientId || !payload.associateId) {
          throw Error;
        }

        dispatch(this.loadDataSuccess(payload));
      } catch (err) {
        dispatch(this.loadDataFailure(err));
        const errorMessage = 'Error! Impersonation failed. Unable to load associate details';

        dispatch(
          messagesActionCreator.addToastMessage({
            type: 'error',
            message: errorMessage,
            id: UtilityHelper.generateUniqueId(),
          })
        );
      }
    };
  },

  updateDataRequest() {
    return {
      type: actionTypes.IMPERSONATION_START_REQUEST,
    };
  },

  updateDataSuccess() {
    return {
      type: actionTypes.IMPERSONATION_START_SUCCESS,
    };
  },

  updateDataFailure() {
    return {
      type: actionTypes.IMPERSONATION_START_FAILURE,
    };
  },

  startImpersonation(impersonationData) {
    return async (dispatch) => {
      try {
        dispatch(this.updateDataRequest());

        const { headers } = await http.startImpersonation(impersonationData);

        localStorage.setItem('adp-acting-session-id', headers['adp-acting-session-id']);
        localStorage.setItem(
          'adp-acting-session-expires-at',
          headers['adp-acting-session-expires-at']
        );

        dispatch(
          messagesActionCreator.addToastMessage({
            type: 'success',
            message: 'Success! Impersonation started.',
            id: UtilityHelper.generateUniqueId(),
          })
        );

        dispatch(this.updateDataSuccess());
      } catch (err) {
        const errorDetail = err.message || '';
        const errorMessage = `Error! Impersonation failed. ${errorDetail}`;

        dispatch(
          messagesActionCreator.addToastMessage({
            type: 'error',
            message: errorMessage,
            id: UtilityHelper.generateUniqueId(),
          })
        );
        dispatch(this.updateDataFailure());
        throw err;
      }
    };
  },
};

export default startImpersonationActionCreator;
