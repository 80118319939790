import {
  checkAuthentication,
  login,
  logout,
  logoutAllSessions,
  getAuthenticationMethod,
  checkAuthPath,
} from './authentication';
import {
  loadAndVerify,
  execute,
  validate,
  destroy,
  prepareCodeEmailVerification,
  createAccount,
  requestPasswordReset,
  requestForgotUsername,
  requestSelfRegistration,
} from './tokens';
import {
  getMyPersonalInfo,
  setMyPersonalInfo,
  getMyDevices,
  revokeDeviceAccess,
  changeMyPassword,
  resendVerificationEmail,
  getMyClientInfo,
  getMySorContactInfo,
  getMyAssociates,
  setMyActiveAssociate,
  getMyContactInfo,
  checkEmailUniqueness,
  checkDob,
  checkForDobObligation,
  checkIfDobExists,
  provisionAllClientAccess,
  getPrefixMatchClientName,
} from './identity';
import {
  getAssociateInfo,
  startImpersonation,
  getAssociateFromImpersonationId,
} from './impersonation';
import getSettingsByGroupName from './saml';
import prolongSession from './session';
import { getLegalAgreementsByKeys, agreeToLegalAgreements } from './legalAgreements';
import { supportedErrorCodes } from './HttpClient';
import logger from './logger';

export default {
  login,
  logout,
  logoutAllSessions,
  checkAuthentication,
  checkAuthPath,
  loadAndVerify,
  checkDob,
  checkForDobObligation,
  checkIfDobExists,
  execute,
  validate,
  destroy,
  prepareCodeEmailVerification,
  createAccount,
  requestPasswordReset,
  requestForgotUsername,
  requestSelfRegistration,
  getMyPersonalInfo,
  setMyPersonalInfo,
  getMyDevices,
  revokeDeviceAccess,
  changeMyPassword,
  resendVerificationEmail,
  getSettingsByGroupName,
  getMyClientInfo,
  getMyAssociates,
  getAssociateInfo,
  startImpersonation,
  getAssociateFromImpersonationId,
  setMyActiveAssociate,
  prolongSession,
  checkEmailUniqueness,
  getMyContactInfo,
  getMySorContactInfo,
  getLegalAgreementsByKeys,
  agreeToLegalAgreements,
  getAuthenticationMethod,
  logger,
  supportedErrorCodes,
  provisionAllClientAccess,
  getPrefixMatchClientName,
};
