import HttpClient from './HttpClient';
import getConstants from '../constants';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;
const httpClient = HttpClient.createInstance({ baseURL: identityOrchestratorUrl });
const identityOrchestratorUrlV1 = constants.serviceBaseUrls.identityOrchestratorV1;
const httpClientV1 = HttpClient.createInstance({ baseURL: identityOrchestratorUrlV1 });

export function getMyPersonalInfo() {
  return httpClient.get('/persons/me');
}

export function setMyPersonalInfo(data) {
  return httpClient.put('/persons/me', data);
}

export function getMyDevices() {
  return httpClient.get('/persons/me/devices');
}

export function revokeDeviceAccess(deviceId) {
  return httpClient.delete(`/devices/${deviceId}/authenticationTokens`);
}

export function changeMyPassword(data) {
  return httpClient.post('/persons/me/credentials', data);
}

export function resendVerificationEmail() {
  return httpClient.post('/persons/me/tokens', { kind: 'emailVerification' });
}

export function getMyClientInfo() {
  return httpClient.get('/associates/me/clientInfo');
}

export async function provisionAllClientAccess(
  clientId,
  lifionOpsAssociateId,
  allClientAccessRoles
) {
  return httpClientV1.post('/provisionAllClientAccess', {
    clientId,
    lifionOpsAssociateId,
    allClientAccessRoles,
  });
}

export async function getPrefixMatchClientName(clientNamePrefix) {
  return httpClient.post('/clm/getPrefixMatch/clientName', {
    clientNamePrefix,
  });
}

export function getMyAssociates() {
  return httpClient.get('/persons/me/associates?extended=true');
}

export function setMyActiveAssociate(associateId, clientId, isNexoClient, isAuthenticatedByNexo) {
  return httpClientV1.put('/sessions/me/associates', {
    associateId,
    clientId,
    isNexoClient,
    isAuthenticatedByNexo,
  });
}

export function checkEmailUniqueness(email) {
  return httpClient.get(`/persons/email-addresses/check?emailAddress=${encodeURIComponent(email)}`);
}

export function getMyContactInfo() {
  return httpClient.get('/persons/me/contact-info');
}

export function getMySorContactInfo() {
  return httpClient.get('/associates/me/contact-info-sor');
}

export function checkDob(clientId, associateId, dob) {
  return httpClient.post(`/clients/${clientId}/associates/${associateId}/verify`, { dob });
}

export function checkForDobObligation(clientId, associateId) {
  return httpClient.get(`/clients/${clientId}/associates/${associateId}/registration-settings`);
}

export function checkIfDobExists(clientId, associateId) {
  return httpClient.get(`/clients/${clientId}/associates/${associateId}/attributes`);
}
