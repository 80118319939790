import SearchBarGroup from './SearchBarGroup';
import SearchResult from './SearchResult';
import { Modal, Button, Loader } from '../../../../components';
import { staticConstants } from '../../../../utils/constants';
import http from '../../../../utils/http';
import UtilityHelper from '../../../../utils/helpers';
import './search.scss';
import messagesActionCreator from '../../../../actioncreators/messages';
import { PageHeader } from '@idm/ui-components';

const { toastMessage } = staticConstants;

import { connect } from 'react-redux';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Search extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    searchFilters: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    associates: PropTypes.arrayOf(
      PropTypes.shape({
        associateId: PropTypes.string,
        clientId: PropTypes.string,
        isNexoClient: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isCurrentlySelectedAssociate: PropTypes.bool,
        isPersonalAssociate: PropTypes.bool,
        client: PropTypes.object,
      }).isRequired
    ).isRequired,
    allClientAccessRoles: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        ticketUrl: PropTypes.string,
      }).isRequired
    ).isRequired,
    dispatchProvisionFailureToastMessage: PropTypes.func.isRequired,
    dispatchProvisionSuccessToastMessage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isConfirmButtonEnabled: false,
      selectedRow: {},
      searchResults: [],
      currentPageData: [],
      currentPageNumber: 1,
      isWaitingForResults: false,
      pageSize: 5,
      searchError: false,
      confirmButtonActionInProgress: false,
      totalCount: 0,
    };
  }

  fetchSearchResults = async (searchPhrase) => {
    this.setState({ isWaitingForResults: true });
    const { pageSize } = this.state;

    let results;

    try {
      const { data: clientsSearchResponse } = await http.getPrefixMatchClientName(searchPhrase);

      const { allClients, totalCount } = clientsSearchResponse;

      results = allClients || [];

      this.setState({
        searchResults: results,
        searchResultsSize: results.length,
        currentPageData: results.slice(0, pageSize),
        currentPageNumber: 1,
        isWaitingForResults: false,
        searchError: false,
        totalCount,
      });
    } catch (error) {
      this.setState({
        searchError: true,
        isWaitingForResults: false,
      });
    }
  };

  handlePageNumberChange = (pageNumber) => {
    const { searchResults, pageSize } = this.state;

    const newPageData = searchResults.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

    this.setState({
      currentPageData: newPageData,
      currentPageNumber: pageNumber,
      selectedRow: {},
    });
  };

  onClickRow = (record) => {
    this.setState({ isConfirmButtonEnabled: true });
    this.setState({ selectedRow: record });
  };

  handleModalConfirm = async () => {
    const { associates, allClientAccessRoles } = this.props;
    const { selectedRow } = this.state;

    this.setState({
      confirmButtonActionInProgress: true,
    });

    const lifionOpsAssociate = associates.find((associate) => associate.clientId === '002');

    const clientId = selectedRow._id;
    const { associateId: lifionOpsAssociateId } = lifionOpsAssociate;

    try {
      await http.provisionAllClientAccess(clientId, lifionOpsAssociateId, allClientAccessRoles);

      this.setState({
        confirmButtonActionInProgress: false,
      });

      await this.props.dispatchProvisionSuccessToastMessage();
    } catch (error) {
      this.setState({
        confirmButtonActionInProgress: false,
      });
      await this.props.dispatchProvisionFailureToastMessage();
    }

    await this.props.onCancel();
    await new Promise((resolve) => setTimeout(resolve, 500));

    window.location.reload();
  };

  renderModalFooterButtons = () => [
    <Button
      buttonType="cancel"
      onClick={this.handleCancelClick}
      value="Cancel"
      key="cancel"
      data-meta-id="add-client-cancellation-button"
    />,
    <Button
      buttonType="primary"
      onClick={this.handleModalConfirm}
      value="Confirm"
      key="confirm"
      data-meta-id="add-client-confirmation-button"
      disabled={!this.state.isConfirmButtonEnabled}
    />,
  ];

  handleCancelClick = async () => {
    await this.props.onCancel();
  };

  renderModalBody() {
    const { title, searchFilters } = this.props;
    const {
      pageSize,
      currentPageData,
      isWaitingForResults,
      currentPageNumber,
      searchResultsSize,
      searchError,
      confirmButtonActionInProgress,
      selectedRow,
      totalCount,
    } = this.state;

    return (
      <section className="results-content">
        <PageHeader className="search-header">{title}</PageHeader>
        <div className="search-bar-container">
          <SearchBarGroup
            searchFilters={searchFilters}
            fetchSearchResults={this.fetchSearchResults}
            totalCount={totalCount}
            {...this.props}
          />
          {confirmButtonActionInProgress && <Loader className="data-loader" />}
          <SearchResult
            onPageChange={this.handlePageNumberChange}
            onClickRow={this.onClickRow}
            pageSize={pageSize}
            currentPageData={currentPageData}
            isWaitingForResults={isWaitingForResults}
            currentPageNumber={currentPageNumber}
            searchResultsSize={searchResultsSize}
            searchError={searchError}
            selectedRow={selectedRow}
            {...this.props}
          />
        </div>
      </section>
    );
  }

  render() {
    const { isVisible, onCancel } = this.props;

    const modalProps = {
      footer: this.renderModalFooterButtons(),
      isVisible,
      title: 'Add Client',
      width: 750,
      className: 'add-client-admin',
      onCancel,
    };

    return <Modal {...modalProps}>{this.renderModalBody()}</Modal>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchProvisionFailureToastMessage() {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: 'PROVISION_ALL_CLIENT_ACCESS_FAILURE_IN_CLIENT',
        type: toastMessage.FAILURE_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
        translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
      });

      dispatch(toastMessageAction);
    },
    dispatchProvisionSuccessToastMessage() {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: 'PROVISION_ALL_CLIENT_ACCESS_SUCCESS_IN_CLIENT',
        type: toastMessage.SUCCESS_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
        translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
      });

      dispatch(toastMessageAction);
    },
  };
}

const SearchPage = connect(() => {}, mapDispatchToProps)(Search);

export default SearchPage;
