import HttpClient from './HttpClient';
import getConstants from '../constants';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;
const identityHttpClient = HttpClient.createInstance({ baseURL: identityOrchestratorUrl });

export function getAssociateInfo(associateId, clientId) {
  return identityHttpClient.get(`/clients/${clientId}/associates/${associateId}`);
}

export function startImpersonation(impersonationData) {
  return identityHttpClient.put('/sessions/current/impersonation/start', impersonationData);
}

export function getAssociateFromImpersonationId(impersonationId) {
  return identityHttpClient.get(`/sessions/impersonation/${impersonationId}`);
}
